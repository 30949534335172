import classes from "./OverseesAndElections.module.css";
import React from "react";
import BgWithText from "../../components/BgWithText/BgWithText";
import ALS from "../../assets/ALS.png";
import { ADVANCED_LEADERSHIP_CARDS } from "../../constants";

const OverseesAndElections = () => {
  return (
    <div>
      <BgWithText title="Harvard Overseer and HAA Elections" image="bg2" />
      <div className={classes.Container}>
        <div className={classes.ContainerHead}>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <div className="LargeText">
              Harvard Overseer {window.screen.width >= 600 && <br />}
              <span className="brown"> HAA Elections </span>
            </div>
            <p>
              <span className="brown bold"> Harvard Overseer </span> and{" "}
              <span className="brown bold"> HAA Elections </span>
              begin on March 31st. These are very important positions, so please
              vote thoughtfully. The alumni Nominating Committee has assembled a
              really strong slate of candidates, whose bios you can check out{" "}
              <a
                href="https://elections.harvard.edu/candidates"
                className="brown bold"
              >
                here{" "}
              </a>
              . <br></br>Most alumni don't vote in
              <span className="brown bold"> Harvard's elections </span> , but
              please do! Anyone with any
              <span className="brown bold"> Harvard degree </span> is eligible
              to vote.
            </p>
            <p style={{ marginBlockStart: "0" }}>
              <span className="brown bold"> Vanessa Liu AB '96 JD '03 </span>,
              who is a candidate for the Board of Overseers,
              <br></br>is known to the Harvard Club of Silicon Valley’s
              leadership through her work on the board of the Harvard Alumni
              Association.<br></br>She is the immediate past president of the
              HAA and a technology entrepreneur committed to empowering diverse
              leaders and helping organizations utilize digital solutions to
              create opportunity.
            </p>
            <p style={{ marginBlockStart: "0" }}>
              As HAA president in 2021-22 and on its Executive Board before
              that, Vanessa focused on bringing alumni together to pursue
              positive social change and kept diversity,<br></br> equity and
              inclusion at the core of her work: rolling out allyship
              programming, participating in HAA’s Anti-Racism Working Group, and
              galvanizing HAA and other leaders to speak out in support of
              affirmative action at events, in the media, and in Court filings.
              <br></br>
              She co-founded the
              <span className="brown bold"> NY chapter </span> of Harvard Alumni
              Entrepreneurs and has been an alumni interviewer for over 20 years
              (for the Harvard Clubs of the Netherlands, United Kingdom and New
              York).
            </p>
            <p style={{ marginBlockStart: "0" }}>
              Vanessa is Co-Founder and CEO of{" "}
              <span className="brown bold"> Sugarwork </span>, a tech startup
              helping companies hold onto an aging workforce through flexible
              arrangements and knowledge transfer, with a goal of tackling
              ageism head on. <br></br>Vanessa also serves as board director of
              <span className="brown bold"> Appen </span>, helping ethical
              applications of artificial intelligence, and{" "}
              <span className="brown bold"> Goodman Group </span> , helping
              develop and manage sustainable industrial properties. <br></br>
              Previously, Vanessa guided SAP's venture program dedicated to
              accelerating software start-ups led primarily by women and members
              of underrepresented groups, convinced that diversity in the tech
              sector leads to better innovation. She spent 10 years with
              <span className="brown bold"> McKinsey’s telecom </span>, media,
              and technology practice in Amsterdam and London, and was COO of
              start-up incubator Trigger Media, where she co-founded InsideHook
              and Fevo.
            </p>
            <p style={{ marginBlockStart: "0" }}>
              She has been endorsed by the Coalition for a Diverse Harvard,
              First Generation Harvard Alumni, Harvard Asian American Alumni
              Alliance, Harvard Black Alumni Society, Harvard Gender & Sexuality
              Caucus, Harvard Latino Alumni Alliance, Harvard South Asian Alumni
              Alliance, Harvard Tamil Sangam Alumni and Native American Alumni
              of Harvard University. More details{" "}
              <a href="https://www.diverseharvard.org/" className="brown bold">
                here{" "}
              </a>{" "}
              on the Coalition and their endorsed candidates.
            </p>
          </div>
          <div className={classes.ImageContainer}>
            <img className={classes.GraduateImage} src={ALS} alt="" />
          </div>
        </div>
        <div className={classes.TextsContainerBold}>
          <p>
            Voting is easy and starts on March 31 - look out for an email from{" "}
            <a
              href="mailto:harvard@mg.electionservicescorp.com"
              target="_blank"
              rel="noreferrer"
              className="brown underline bold"
            >
              harvard@mg.electionservicescorp.com
            </a>{" "}
            with a link to your online ballot on March 31 (check spam if you
            can't find it). If you’re interested in pursuing the 2022 ALI
            Fellowship, beginning in January 2022, or want to nominate a
            candidate, you can read more about the application process .
          </p>
          <p>
            Paper ballots will also be mailed, unless you have opted out of
            paper.
          </p>
          <p>
            Problems? Call Election Services at{" "}
            <a href="tel:+18667204357" className="brown underline bold">
              +1-866-720-4357
            </a>{" "}
            (M-F, 9am-5pm ET) or email{" "}
            <a
              href="mailto:harvard@electionservicescorp.com"
              target="_blank"
              rel="noreferrer"
              className="brown underline bold"
            >
              harvard@electionservicescorp.com
            </a>{" "}
            as well as{" "}
            <a
              href="mailto:harvardcoalition@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="brown underline bold"
            >
              harvardcoalition@gmail.com
            </a>{" "}
            with your name, degree, and graduation year.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OverseesAndElections;
