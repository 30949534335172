import AdvanceLeadership from "./assets/AdvancedLeadership.png";
import Alumini from "./assets/alumini1.png";
import HarvardShield from "./assets/harvardShield.png";
import Morganlewis from "./assets/morganlewis.png";
import ALS from "./assets/ALS.png";
import p1 from "./assets/p1.png";
import p2 from "./assets/p2.png";
import p3 from "./assets/p3.png";
import p4 from "./assets/p4.png";
import p5 from "./assets/p5.png";

import winner1 from "./assets/winner1.png";
import winner2 from "./assets/winner2.png";
import winner3 from "./assets/winner3.png";
import winner4 from "./assets/winner4.png";
import winner5 from "./assets/winner5.png";
import winner6 from "./assets/winner6.png";

import Niki from "./assets/Niki.png";
import Daniel from "./assets/Daniel.png";
import Oriana from "./assets/OrianaLiHalevy.jpg";
import Terry from "./assets/TerryErisman.png";
import John from "./assets/JohnYook.png";
import Shaun from "./assets/Shaun.png";
import Doug from "./assets/Doug.png";
import Vivian from "./assets/VivianLee.jpg";

export const TOAST_ERR = "Please try after some time!";

// context
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

export const PATHS = {
  HOME: "/",
  ABOUT_US: "aboutUs",
  SCHOOLS: "schools",
  BOOK_PRIZE: "bookPrize",
  SERVICES: "services",
  VOLUNTEER: "volunteer",
  JOIN_US: "joinUs",
  REGISTER: "register",
  PROFILE: "profile",
  PRIVACY_POLICY: "privacyPolicy",
  LOGIN: "auth/login",
  FORGET_PASSWORD: "auth/forgetPassword",
};

export const HEADER_LINKS = [
  { name: "Welcome", path: "/" },
  { name: "About", path: "aboutUs" },
  { name: "Events", path: "events" },
  { name: "Schools", path: "schools" },
  { name: "Book Prize", path: "bookPrize" },
  { name: "Service", path: "services" },
  { name: "Volunteer", path: "volunteer" },
  { name: "Join", path: "joinUs" },
];

export const MOBILE_HEADER_LINKS = [
  { name: "Welcome", path: "/" },
  { name: "About", path: "aboutUs" },
  { name: "Events", path: "events" },
  { name: "Schools", path: "schools" },
  { name: "Book Prize", path: "bookPrize" },
  { name: "Service", path: "services" },
  { name: "Volunteer", path: "volunteer" },
  { name: "Join", path: "joinUs" },
  { name: "Profile", path: "profile" },
];

export const ANNOUNCEMENTS = [
  {
    title: (
      <div>
        <span className="PrimaryTextBold">Harvard Club </span>Welcomes <br />{" "}
        the Class of 2023
      </div>
    ),
    contents: [
      `The Harvard Club of Silicon Valley welcomes all graduates of the Class of 2021 to the beautiful Bay Area. Think of the HCSV as your local connection to our beloved alma mater.`,
      `Upon successfully registering with our website, all those affiliated with the Class of 2021— both grads and undergrads — will automatically receive an Individual Membership for 2021, compliments of the Harvard Club of Silicon Valley.`,
      `Members receive informative newsletters, discount pricing to events, access to a powerful and resourceful network, and much more.`,
    ],
    buttonText: "Register Today",
    link: "/register",
    image: Alumini,
  },
  {
    title: (
      <div>
        <span className="PrimaryTextBold">Harvard Overseer </span>and HAA
        Elections: <br />
      </div>
    ),
    contents: [
      `Harvard Overseer and HAA Elections begin on March 31st. These are very important positions, so please vote thoughtfully.`,
      `The alumni Nominating Committee has assembled a really strong slate of candidates, whose bios you can check out.`,
      `ALI Fellows focus on education, public health, gender equality, social justice, climate change, and other significant social issues.`,
      `Most alumni don't vote in Harvard's elections, but please do! Anyone with any Harvard degree is eligible to vote.`,
    ],
    buttonText: "Read More",
    link: "elections",
    image: ALS,
  },
  // {
  //   title: (
  //     <div>
  //       <span className="PrimaryTextBold">Harvard Overseer </span>and HAA
  //       Elections: <br />
  //     </div>
  //   ),
  //   contents: [
  //     `As a member of the University’s alumni community, please help Harvard’s Advanced Leadership Initiative identify distinguished leaders for the 2021 ALI Fellowship.`,
  //     `As featured in the New York Times and the Harvard Gazette, ALI is a year-long educational fellowship for highly accomplished leaders transitioning from their primary careers to take on the world’s most pressing problems.`,
  //     `ALI Fellows focus on education, public health, gender equality, social justice, climate change, and other significant social issues.`,
  //   ],
  //   buttonText: "Read More",
  //   link: "advancedLeadership",
  //   image: AdvanceLeadership,
  // },
  {
    title: (
      <div>
        Join the <span className="PrimaryTextBold">Hcsv Today</span>
      </div>
    ),
    contents: [
      `It’s easy to become a member of, or renew your membership to, the Harvard Club of Silicon Valley for 2021. Your annual dues helps support a number or HCSV programs and events, and Harvard alumni of all ages, backgrounds, and interests.`,
      `Submit your membership dues and confirm your email address today and be one of hundreds participating in HCSV events, receiving discounted admission, and supporting one of the best Harvard alumni club in the West.`,
    ],
    link: "joinUs",
    buttonText: "Join Today",
    image: HarvardShield,
  },
  {
    title: (
      <div>
        Premier Sponsor :
        <span className="PrimaryTextBold">
          {" "}
          <br /> Morgan Lewis
        </span>
      </div>
    ),
    contents: [
      `Morgan Lewis is always on—providing high-quality litigation, corporate, labor, and intellectual property services to clients across industry sectors and regions around the world. Whether you’re today’s industry leader or tomorrow’s game-changer, Morgan Lewis is always responsive and always on.`,
      `The Harvard Club of Silicon Valley Board of Directors deeply appreciates Morgan Lewis for its continuing support of the Harvard alumni community throughout Northern California.`,
    ],
    buttonText: "Know More",
    anchor: "https://www.morganlewis.com/",
    image: Morganlewis,
  },
];

export const FUTURE_EVENTS = [
  "Guest Speakers",
  "Social Activities",
  "Professional/Networking Events",
  "Community Service Projects",
  "Joint Events with other clubs",
  "Recreational Events",
];

export const PAYMENT_IMAGES = [p1, p2, p3, p4, p5];

export const ADVANCED_LEADERSHIP_CARDS = [
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Cross-University </span> <br /> Engagement
      </div>
    ),
    content:
      "The fellowship includes a rigorous core academic program led by distinguished faculty from across Harvard University.",
  },
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Calendar Year </span> <br /> Schedule
      </div>
    ),
    content:
      "Calendar Year Schedule. ALI Fellows join Harvard for a calendar year (January-December) with considerable individual flexibility.",
  },
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Diverse Experiences, </span> <br /> Common
        Purpose
      </div>
    ),
    content:
      "ALI Fellows have 20+ years of leadership experience and a shared commitment to lasting social impact.",
  },
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Emphasis on </span> <br /> Action
      </div>
    ),
    content:
      "Fellows focus on outcomes—identifying and making progress on a promising project for social good.",
  },
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Calendar Year </span> <br /> Schedule
      </div>
    ),
    content:
      "A spouse or life partner can accompany the ALI Fellow as a program participant.",
  },
  {
    title: (
      <div className="whiteCard">
        <span className="brown"> Unique </span> <br /> Coalition
      </div>
    ),
    content:
      "The ALI experience continues beyond the fellowship year with annual gatherings to foster lasting relationships and impact.",
  },
];

/*--------------BOOK PRIZE--------------------*/

export const WINNERS_LIST = [
  {
    image: winner1,
    name: "Audrey Chang",
    school: "Los Altos High School",
  },
  {
    image: winner2,
    name: "Christina Cheng",
    school: "Woodside Priory School",
  },
  {
    image: winner3,
    name: "Samantha Chock",
    school: "Leland High School",
  },
  {
    image: winner4,
    name: "Derek Nease",
    school: "Lydian Academy",
  },
  {
    image: winner5,
    name: "Kaitlyn Tsai",
    school: "Saratoga High School",
  },
  {
    image: winner6,
    name: "Angela Wong",
    school: "Gunn High School",
  },
];

export const WINNERS_HISTORY = [
  {
    year: "2020 Winners",
    content: [
      "Kylie Akiyama – Los Altos High School",
      "Neharika Jain – Notre Dame High School",
      "Rithu Paramesh – Presentation High School",
      "Mallika Parulekar – Henry M. Gunn High School",
      "Michael Tang – Saratoga High School",
    ],
  },
  {
    year: "2018 Winners",
    content: [
      "Alicia Chu – Henry M. Gunn High School",
      "Anya Gupta – Los Altos High School",
      "Chelsea Leung – Saratoga High School",
      "Regina Ta – Presentation High School",
      "Cassidy Wallace – Notre Dame High School",
    ],
  },
  {
    year: "2017 Winners",
    content: [
      "Clarissa Daniel – Henry M. Gunn High School",
      "William Luo – Lynbrook High School",
      "Mashia Mazumder – Notre Dame High School",
      "Sarah (Yage) Pi – Leigh High School",
      "Anna Piskun – Presentation High School",
      "Karen Xia – Mountain View High School",
    ],
  },
  {
    year: "2016 Winners",
    content: [
      "Sean Adibi – Los Altos High School",
      "Andrew Rao – Valley Christian High School",
      "William Shan – Lynbrook High School",
      "Kaitlynn Sierra – Presentation High School",
    ],
  },
  {
    year: "2015 Winners",
    content: [
      "Cayla Barsotti – Prospect High School",
      "Jeffrey Chang – Lynbrook High School",
      "Annette Chu – Presentation High School",
      "Giulia Valentina Corno – Saratoga High School",
      "Kimberly Lew – Piedmont Hills High School",
      "Robin Peter – Henry M. Gunn High School",
      "Rebecca Shaffer – Notre Dame High School",
      "Misbah Surani – Milpitas High School",
      "Kelyn Wood – Mountain View High School",
    ],
  },
];

/*--------------ABOUT US--------------------*/

export const BOARD_OF_DIRECTORS = [
  {
    image: Niki,
    name: "Niki V. Santo",
    position: "Co-President",
  },
  {
    image: Shaun,
    name: "Shaun Chaudhari",
    position: "Co-President",
  },
  {
    image: Daniel,
    name: "Daniel Ahn",
    position: "Sponsorship Chair",
  },
  {
    image: Doug,
    name: "Douglas Y. Park",
    position: "General Counsel",
  },
  {
    image: Oriana,
    name: "Oriana Li Halevy",
    position: "Strategic Partnerships Chair",
  },
  {
    image: Terry,
    name: "Terry Erisman",
    position: "Schools Committee Chair",
  },
  {
    image: Vivian,
    name: "Vivian Lee",
    position: "Book Prize Chair",
  },
  {
    image: John,
    name: "John Yook",
    position: "Outdoor Activities Chair",
  },
];

/*--------------------*/

export const MEMBERSHIP_LEVELS = [
  {
    price: "$100",
    title: "Patron",
    content:
      "Allows you and all your guests to take advantage of the membership rate at 2020-21 events.",
  },
  {
    price: "$50",
    title: "Sponsor",
    content:
      "Allows you and one guest to take advantage of the membership rate at 2020-21 events.",
  },
  {
    price: "$25",
    title: "Recent Graduate",
    content: "(Undergraduate Classes of 2016 thru 2020)",
  },
  {
    price: "$35",
    title: "Parent",
  },
  {
    price: "$35",
    title: "Individual",
  },
  {
    price: "$15",
    title: "Student",
  },
];

export const MEMBERSHIP_TEXT = {
  patron:
    "Allows you and all your guests to take advantage of the membership rate at 2020-21 events.",
  sponsor:
    "Allows you and one guest to take advantage of the membership rate at 2020-21 events.",
  recentgraduate: "(Undergraduate Classes of 2016 thru 2020)",
};

/*--------SERVICES-----------*/

export const RESUME_INFO = [
  { field: "Name :", value: "Amisha Kambath" },
  { field: "Class Year :", value: "2022" },
  { field: "House :", value: "Dunster" },
  {
    field: "Concentration/ Secondary :",
    value: "Social Studies and Secondary in Economics",
  },
  {
    field: "Clubs/activities :",
    value:
      "IOP Policy Program, Harvard College Project for Justice, South Asian Women’s Collective, Club Basketball",
  },
  { field: "Fun fact :", value: "I’m a massive Roger Federer fan!" },
  {
    field: "Organization :",
    value: "Ella Baker Center for Human Rights (Oakland, CA)",
  },
  {
    field: "Organization’s Mission :",
    value:
      "The Ella Baker Center works locally, statewide, and nationally to shift resources away from prisons and punishment and towards opportunities that make communities safe, healthy, and strong.",
  },
  {
    field: "Scope of Project :",
    value:
      "Amisha will be working as a Research Fellow and her work will include:",
  },
];

export const SCOPE_LIST = [
  "Youth justice: Researching alternatives to incarceration in Alameda County and alternative responses for youth who have committed serious or violent offenses.",
  "Strategizing advocacy and organizing efforts with two community-based coalitions: the Free Our Kids Coalition in Alameda County and the California Alliance for Community and Youth Justice (CAYCJ) for California-related advocacy.",
  "Research efforts on the history of family separation in the United States, focusing on the juvenile justice system, child welfare system, and the adult criminal court system under the Executive Director Zach Norris (Harvard College ’99).",
  "Identifying restorative economic approaches applicable to the City of Oakland and strategies local governments can take to remedy historic inequities.",
];

/*-------------EVENTS REGISTRATION-----------------*/

export const PRICE_TYPES = [
  {
    name: "Patron",
    id: "patron",
  },
  {
    name: "Sponsor",
    id: "sponsor",
  },
  {
    name: "Recent Graduate",
    id: "recentGraduate",
  },
  {
    name: "Individual",
    id: "individual",
  },
  {
    name: "Non Member",
    id: "inActive",
  },
];

//LINKS

export const LINKS = {
  NEW_YORK_TIMES:
    "https://www.nytimes.com/2018/04/05/education/learning/retraining-new-career.html",
  HARVARD_GAZETTE:
    "https://news.harvard.edu/gazette/story/newsplus/new-ali-faculty-chair-welcomes-record-breaking-cohort/",
};

//DROPDOWN VALUES

export const TITLE = [
  "Mr.",
  "Mrs.",
  "Ms.",
  "Miss",
  "Dr.",
  "Hon.",
  "Rev.",
  "Sir",
];

export const SUFFIX = ["Jr.", "Sr.", "II", "III", "IV", "MD", "PhD", "Esq."];

export const AFFILIATION = [
  "Harvard College",
  "Graduate School",
  "Business School",
  "Dental School",
  "School Of Design",
  "School Of Education",
  "JFK School Of Government",
  "Law School",
  "Medical School",
  "School Of Public Health",
  "Divinity School",
  "Non-Harvard Registrant",
];

const TEMP_CLASSES = Array.from({ length: 93 }, (_, i) => {
  return {
    label: (i + 1931).toString(),
    value: i + 1931,
  };
}).reverse();

export const CLASSES = [{ label: "N/A", value: "0" }, ...TEMP_CLASSES];
